import { inYourCartIgnite } from "../inYourCart/inYourCartEmitter";
import particleImage from "src/assets/images/star2.png";

const discountCodeAppliedInitializer = {
  name: "discountCodeAppliedIgnite",
  initializer: inYourCartIgnite,
  texture: particleImage,
};

export { discountCodeAppliedInitializer };

<template>
  <canvas
    ref="stage"
    :width="`${size.width}px`"
    :height="`${size.height}px`"
  ></canvas>
</template>

<script setup>
import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
  nextTick,
  watch,
} from "vue";
import { throttle, useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import Proton from "proton-engine";
import { injectRafManager } from "src/composables/raf/useRafManager";
import { usePreferencesStore } from "src/stores/preferences";

defineOptions({ name: "EmitterComponent" });

const props = defineProps({
  canEmit: { type: Boolean, default: false },
  initializer: { type: Array, required: true, default: () => [] },
  size: {
    type: Object,
    type: Object,
    default: () => ({
      height: 600,
      width: 800,
    }),
  },
  texture: {
    type: String,
    default:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAArlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8tivQqAAAAOnRSTlMAAwcKEhsOFjAkO1VC7aMrH4X25s1idCjy+tTIwbGUgG5mWkpH2Y954L2um2pQNzW5Td21qZeLXuGeJ0D6FAAAAupJREFUWMO1l+mWmlAQhLOYSQSCGlATVhFwRMWNTd//xVLd3gNRjxmTPlN/Zv7UR1W3yr0f3lMflSTeTv/n/gT9M0NZb/Q0onN//kMd40k7u19aMeMZhLKzuUf6CvE/DFGIN/xsZ++XVkxhBBPeevzLxT0c9pWGQ2IA8TiE8is73DBPB4NvrMFgCggYjHhIaP1sn8K822qs7Q6QKSNawl/9bN9pmmEkSRwniWFo2o4Rjwidv0d+2LeakcR5rrPyPE4MbQsEEXqK8NDfn+LpRpzr82Y8jqDxuJnreWwgxbT/gMAAzj/s4/FGctDn42gzm7Bmm2g81w+JgRD9IbcgwF0A7g8/Hg873K/hiBW+ggEEQoBAc7iLcO0/6E00g7teBEFVBcGiBmMWNfrhmnBbgOdHfjx+Eo4Wwfpsss7rYDEKJwhBBJ6kKnETgPxb8uPxdbA2y5XPWpXmOqgRgghbIqgItwFQYIf+8I8WlVn6xT7LPC/L9oVfmtViBALmsKMSKsJ9AC3Rmw38a3NVZJ7r2pDrelmxMtcgbBo90e4jMIADUIFoEpJ/78HtOGnqOGB4eyKEk4hKcAQGtA14BRSACoR1dfE76XEJHVPnQqjqkEpQBF4EjFcNaAL5HAUC8pP9ZLFOhCBCgBLznKegOlw3wAQQAAXKwnOddGlZ31mWtUwd1ytKlEAETOG6A+9ANcAEEMDPXBt+eH9C+AOC7WY+ImAK3IH30AF4B9QAK6gRAAWOFtl/QISwjiiBCDUWQR1oDwzoZsg7yKnBebVHgJPFfkWwToiwX52pQ857UFPsAGoEqgEHgP8XBAJHUB14CC2gXYKaoRqB7Swt5VcEa+nYaghqirSGW0BMgMr0PTu9B6S255sVAeJ3AAgqSIcoXqPogyT/KMu/TPKvs/gHRfiTJv9RFf+sS18s4leb9OUqfr1LDxjiI470kCU95kkPmuKjrvywLT/uyy8c8iuP/NIlv/bJL57yq6/88i3Xb+8uvEkvrnjKAAAAAElFTkSuQmCC",
  },
  timeoutDuration: { type: Number, default: 1000 },
});

const $q = useQuasar();
const rafManager = injectRafManager();
const bus = inject("bus");
const preferencesStore = usePreferencesStore();
const { reducedMotion } = storeToRefs(preferencesStore);
const cleanupFunctions = ref([]);
const proton = ref(null);
const stage = ref(null);
const timeoutId = ref(null);
const computedCanEmit = computed(() => props.canEmit);
const computedSize = computed(() => props.size);
let renderer;

const clearTimeoutId = () => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }
};

const createProton = async () => {
  const context = stage.value.getContext("webgl", {
    willReadFrequently: true,
  });
  context.globalCompositeOperation = "luminosity";
  proton.value = new Proton();
  props.initializer.forEach((cfg) => {
    const emitter = new Proton.Emitter();
    const cleanup = cfg.initializer(
      bus,
      emitter,
      !$q.platform.is.desktop,
      proton.value,
      { width: computedSize.value.width, height: computedSize.value.height },
      props.texture
    );
    if (cleanup) {
      cleanupFunctions.value.push(cleanup);
    }
    emitter.emit();
    proton.value.addEmitter(emitter);
  });
  renderer = new Proton.WebGLRenderer(stage.value);
  renderer.blendFunc("SRC_ALPHA", "ONE");
  proton.value.addRenderer(renderer);
};

const destroyProton = () => {
  if (!proton.value) return;
  try {
    proton.value.destroy();
    rafManager.remove(renderProton);
  } catch (e) {
    console.error(e);
  }
};

const handleEmit = () => {
  computedCanEmit.value
    ? rafManager.resume(renderProton)
    : rafManager.pause(renderProton);
};

const handleResize = (size) => {
  if (renderer) renderer.resize(size.width, size.height);
  bus.emit("emitterResize", { size });
};

const renderProton = () => {
  if (!proton.value) return;
  nextTick(() => {
    proton.value.update();
  });
};

onBeforeUnmount(() => {
  cleanupFunctions.value.forEach((cleanup) => cleanup());
  clearTimeoutId();
  destroyProton();
});

watch(computedCanEmit, () => {
  handleEmit();
});

watch(computedSize, (size) => {
  throttle(handleResize(size), 600);
});

watch(
  reducedMotion,
  (reduced) => {
    if (reduced) {
      timeoutId.value = setTimeout(() => {
        rafManager.pause(renderProton);
      }, props.timeoutDuration);
    } else {
      clearTimeoutId();
      rafManager.resume(renderProton);
    }
  },
  { immediate: true }
);

onMounted(() => {
  nextTick(() => {
    createProton();
    rafManager.add(renderProton);
    handleEmit();
  });
});
</script>

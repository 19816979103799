import { computed } from "vue";
import { defineStore } from "pinia";
import { useDialogStore } from "./dialog";
import { useLayoutStore } from "./layout";
import { useToastStore } from "./toast";
import { discountCodeAppliedInitializer } from "src/emitter/discountCode/discountCodeAppliedInitializer";
import { inYourCartInitializer } from "src/emitter/inYourCart/inYourCartInitializer";

export const useEmitterStore = defineStore("emitter", () => {
  const dialogStore = useDialogStore();
  const layoutStore = useLayoutStore();
  const toastStore = useToastStore();

  const initializers = {
    discountCodeApplied: [discountCodeAppliedInitializer],
    inYourCart: [inYourCartInitializer],
  };

  const canEmitLevel1 = computed(
    () =>
      dialogStore.dialogs.length === 0 &&
      !toastStore.showToast &&
      !layoutStore.zoomEnabled
  );

  const canEmitLevel2 = computed(
    () =>
      dialogStore.dialogs.length >= 1 &&
      !toastStore.showToast &&
      !layoutStore.zoomEnabled
  );

  const getEmitterConfig = (type) => {
    const typeParts = type.split(".");
    const configInitializers = typeParts
      .map((part) => initializers[part])
      .flat();

    return {
      initializer: configInitializers,
    };
  };

  return {
    canEmitLevel1,
    canEmitLevel2,
    getEmitterConfig,
  };
});

import { inYourCartIgnite } from "./inYourCartEmitter";
import particleImage from "src/assets/images/star2.png";

const inYourCartInitializer = {
  name: "inYourCartIgnite",
  initializer: inYourCartIgnite,
  texture: particleImage,
};

export { inYourCartInitializer };

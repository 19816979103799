<template>
  <div v-if="showToast" class="fixed fullscreen" style="z-index: 6001">
    <div class="dimmed fullscreen" style="z-index: -1" />
    <emitter-component
      v-if="emitter?.initializer[0]?.texture"
      can-emit
      :initializer="emitter.initializer"
      :size="{
        height: $q.screen.height,
        width: $q.screen.width,
      }"
      :texture="emitter.initializer[0].texture"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useEmitterStore } from "src/stores/emitter";
import { useToastStore } from "src/stores/toast";
import EmitterComponent from "src/components/emitter/EmitterComponent.vue";

defineOptions({ name: "ToastBackground" });

const emitterStore = useEmitterStore();
const toastStore = useToastStore();
const { showToast, toastType } = storeToRefs(toastStore);

const emitter = computed(() => {
  return emitterStore.getEmitterConfig(toastType.value);
});
</script>

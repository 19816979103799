import { colors } from "quasar";
import Proton from "proton-engine";
import { getRandomValue } from "src/utils/emitter/math/random";

const { getPaletteColor } = colors;

const getRandomColor = () => {
  const color1 = getPaletteColor("primary");
  const color2 = getPaletteColor("accent");
  return Math.random() > 0.5 ? color1 : color2;
};

const inYourCartIgnite = (bus, emitter, isMobile, proton, size, texture) => {
  emitter.rate = new Proton.Rate(new Proton.Span(1, 2), 0.1);
  emitter.removeAllBehaviours();
  emitter.addBehaviour(new Proton.Color(getRandomColor()));
  emitter.addBehaviour(new Proton.Gravity(1));
  emitter.addInitialize(new Proton.Life(0.125, 0.25));
  emitter.addInitialize(new Proton.Mass(1));
  emitter.addInitialize(
    new Proton.P(
      new Proton.LineZone(10, size.height, size.width - 10, size.height)
    )
  );
  emitter.addInitialize(new Proton.Radius(1, 2));
  emitter.addInitialize(
    new Proton.V(new Proton.Span(55, 17), new Proton.Span(0, 0, true), "polar")
  );
  proton.addEventListener(Proton.PARTICLE_DEAD, (particle) => {
    const emitterType = Math.random() < 0.7 ? "first" : "second";
    if (Math.random() < 0.1 || Math.random() < 0.7) {
      createEmitter(particle, proton, texture, emitterType);
      bus.emit("inYourCartIgniteSound");
    }
  });
  bus.emit("inYourCartFireworkSound");
  bus.on("emitterResize", (data) => {
    updateEmitterBoundary(bus, data, emitter);
  });
  return () => {
    bus.off("emitterResize", updateEmitterBoundary);
  };
};

const setCommonProperties = (subemitter, particle, texture) => {
  const randomDriftX = getRandomValue(3, 32);
  const randomDriftY = getRandomValue(3, 32);
  const driftFrequency = getRandomValue(0.03, 0.3);
  subemitter.addInitialize(new Proton.Body(texture));
  subemitter.addBehaviour(
    new Proton.RandomDrift(randomDriftX, randomDriftY, driftFrequency)
  );
  subemitter.addBehaviour(new Proton.Alpha(0.7, 0));
  subemitter.addBehaviour(new Proton.Gravity(getRandomValue(1, 5)));
  subemitter.addBehaviour(new Proton.Color(getRandomColor()));
  subemitter.p.x = particle.p.x;
  subemitter.p.y = particle.p.y;
  subemitter.emit("once", true);
};

const createEmitter = (particle, proton, texture, emitterType) => {
  const subemitter = new Proton.Emitter();
  if (emitterType === "first") {
    subemitter.rate = new Proton.Rate(new Proton.Span(6, 8), 1, 1);
    subemitter.addInitialize(new Proton.Radius(14, 18));
    subemitter.addInitialize(new Proton.Life(0.2, 6));
    subemitter.addBehaviour(new Proton.Scale(0.4, 0.6));
    subemitter.addInitialize(
      new Proton.V(new Proton.Span(2, 4), new Proton.Span(0, 360), "polar")
    );
  } else {
    subemitter.rate = new Proton.Rate(new Proton.Span(2, 4), 1, 1);
    subemitter.addInitialize(new Proton.Radius(8, 12));
    subemitter.addInitialize(new Proton.Life(0.6, 8));
    subemitter.addInitialize(
      new Proton.V([1, 2], new Proton.Span(0, 360), "polar")
    );
    subemitter.addBehaviour(new Proton.Scale(0.6, 1));
  }
  subemitter.addInitialize(new Proton.Mass(1));
  setCommonProperties(subemitter, particle, texture);
  proton.addEmitter(subemitter);
};

const inYourCartExtinguish = (emitter, size, bus) => {
  emitter.removeAllBehaviours();
  setTimeout(() => {
    emitter.stop();
    emitter.removeAllParticles();
    bus.emit("emitterStop");
    bus.emit("inYourCartIgniteStop");
  }, 1500);
};

const updateEmitterBoundary = (bus, data, emitter) => {
  emitter.LineZone =
    (10, data.size.height, data.size.width - 10, data.size.height);
};

export { inYourCartIgnite, inYourCartExtinguish, setCommonProperties };
